<app-header></app-header>
  <div class="bg msg">
      <!-- <div class="intro bg1">
        <div class="container">
          <div class="col">
            <h2>Chairman's Message</h2>
          </div>
        </div>
      </div> -->
      <section>
          <div class="container">
              <div class="flexbox">
                  <div class="col__6">
                      <img src="../../../assets/images/careers.jpg" alt="">
                  </div>
                  <div class="col__6 ml-2 center">
                      <p>Join our team to help shape the future of entertainment for passionate fan communities.</p>
                      <a href="mailto:events@youngspacegroup.com " class="btn">WORK WITH US</a><br>
                      <a href="https://docs.google.com/forms/d/e/1FAIpQLSexmtFNoJMzcv7_K37DWwXb49D3QLNLZV8dIK80CHQK6q-nTg/viewform?usp=sf_link" target="_blank" class="btn ">MODEL REGISTRATION</a><br>
                      <a href="https://docs.google.com/forms/d/e/1FAIpQLSdDAkZD931LEyQ12nGPEJnXftPQUSJvTPR9IkRtbvGYDnQpng/viewform?usp=sf_link" target="_blank" class="btn ">JOB APPLICATION</a>
                  </div>
              </div>
          </div>
      </section>
  </div>
  <app-footer></app-footer>