<app-header></app-header>
<div class="bg msg">
  <!-- <div class="intro bg1">
      <div class="container">
        <div class="col">
          <h2>Chairman's Message</h2>
        </div>
      </div>
    </div> -->
  <section>
    <!-- <div class="col__4">
                    <img src="images/ubl-chairman.jpg" alt="">
                </div> -->
    <div class="row">
      <div class="col-12">
        <h2 class="text-center mb-3">Gallery</h2>
      </div>
    </div>

    <div class="row mt">
      <div class="col-12">
        <div class="gallery mx-3">
          <img
            class="mt-10"
            src="../../../assets/images/gallery/Portfolio.jpg"
            alt=""
          />
          <!-- <img
            class="mt-10"
            src="../../../assets/images/gallery/Private Shoots.jpg"
            alt=""
          /> -->
          <img
            class="mt-10"
            src="../../../assets/images/gallery/Studio.jpg"
            alt=""
          />
          <!-- <img
            class="mt-10"
            src="../../../assets/images/gallery/Wedding SHoots.jpg"
            alt=""
          /> -->
          <img
            class="mt-10"
            src="../../../assets/images/gallery/DJ.jpg"
            alt=""
          />
          <img
            class="mt-10"
            src="../../../assets/images/gallery/Event.jpg"
            alt=""
          />
          <img
            class="mt-10"
            src="../../../assets/images/gallery/fashion-show.jpg"
            alt=""
          />
          <!-- <img
            class="mt-10"
            src="../../../assets/images/gallery/Model Training.jpg"
            alt=""
          /> -->
          <!-- <img
            class="mt-10"
            src="../../../assets/images/gallery/Modelling.jpg"
            alt=""
          /> -->
          <img
            class="mt-10"
            src="../../../assets/images/gallery/Organising.jpg"
            alt=""
          />
          <!-- <img
            class="mt-10"
            src="../../../assets/images/gallery/Party Organizer.jpg"
            alt=""
          /> -->
          <!-- <img
            class="mt-10"
            src="../../../assets/images/gallery/Perform Arts.jpg"
            alt=""
          /> -->
        </div>
        <div class="gallery mx-3 mt-5">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/GewObMdU_B8"
            title="YouTube video player"
            class="mx-auto"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/A0r8_rSkmtI"
            title="YouTube video player"
            class="mx-auto"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="gallery mx-3 mt-5">
          <div class="row">
            <div class="col-lg-6">
              <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/7UKTu7BgGZ4"
              title="YouTube video player"
              class="mx-auto"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            </div>
            <div class="col-lg-6">
              <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/t27UMIWj8U0"
              title="YouTube video player"
              class="mx-auto"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            </div>
          </div>

        </div>

        <div class="gallery mx-3 mt-5">
          <div class="row">
            <div class="col-lg-6">
              <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/kgeOz9IXYAI"
              title="YouTube video player"
              class="mx-auto"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            </div>
            <div class="col-lg-6">
              <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/-GQft297g88"
              title="YouTube video player"
              class="mx-auto"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            </div>
          </div>

        </div>

        <div class="gallery mx-3 mt-5">
          <div class="row">
            <div class="col-lg-6">
              <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/PxkvIdIzpNQ"
              title="YouTube video player"
              class="mx-auto"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            </div>
            <div class="col-lg-6">
              <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/Hk_xQHGk7xw"
              title="YouTube video player"
              class="mx-auto"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            </div>
          </div>

        </div>

      </div>
    </div>
  </section>
</div>
<app-footer></app-footer>
