<div class="wrapper">
  <app-header></app-header>
    <section class="bg msg">
        <div class="container">
            <div class="flexbox">
                <div class="col__6">
                    <img src="../../../assets/images/About US.jpeg" alt="">
                </div>
                <div class="col__6">
                    <p>
                      Young Space has some expertise in delivering inventive, advanced and bleeding edge events, utilizing a center conviction that narrating drives our innovative methodology. Established by pioneers in the diversion and event generation industry with broad specialized aptitude.
                      Young Space assorted group likewise incorporates foundations in theater, media, and brand promoting. Through its profound experience and innumerable preparations, Young Space comprehends that to genuinely connect with a visitor, thoughts must be completely acknowledged down to the littlest detail.
                      We are grant winning industry pioneers giving complete gathering and event coordination and inventive generation the executives. We offer key direction and program the board, from idea to conveyance to ROI investigation. With a solid group of people and substantial information base of the event industry, Young Space make each event, regardless of what estimate, a colossal achievement.
                      Regardless of whether your gathering or event is a corporate meeting, fantastic opening, acknowledgment event or item dispatch, you can confide in our expert organizers to make your extraordinary event an important accomplishment through our uncommon event arranging administrations in Middle East, London, India and around the globe.
                      </p>   
                </div>
            </div>
        </div>
      </section>
    <section class="shade">
        <div class="container">
            <div class="vision__mission">
              <div class="col__6">
                <div class="vision__mission--box">
                  <img src="../../../assets/images/vision.jpg" alt="" style="
                  height: 220px;
                  width: 100%;
              ">
                  <div class="col">
                    <h2>Vision</h2>
                    <p>Perfection is not attainable, but if we chase perfection we can catch excellence. The young do not know enough to be prudent, and therefore they attempt the impossible, and achieve it, generation after generation.</p>
                  </div>
                </div>
              </div>
              <div class="col__6">
                <div class="vision__mission--box">
                  <img src="../../../assets/images/mission.jpg" alt="">
                  <div class="col">
                    <h2>Mission</h2>
                    <p>Organizing is a journey, not a destination. I believe that space travel will one day become as common as airline travel is today. Earth is a small town with many neighborhoods in a very big universe. So expect unexpected fabulous events; even in Space. A place for everything, and everything in its place. </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </section>
   <app-footer></app-footer>
</div>