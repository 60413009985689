<footer class="footer__inner">
    <div class="container">
        
            <ul >
                <li>
                    <a [routerLink]="['/message']" >Chairman's Message</a>
                </li>
                <li>
                    <a [routerLink]="['/gallery']" >Gallery</a>
                  </li> 
                  <li>
                      <a [routerLink]="['/services']" >Our Service</a>
                    </li> 
                <li>
                    <a [routerLink]="['/about']" >About Us</a>
                </li>
                <!-- <li>
                    <a href="team.html">Our Team</a>
                </li> -->
                <li>
                  <a [routerLink]="['/registration']" >Registration</a>
                </li> 
                <li>
                    <a [routerLink]="['/contact']" >Contact</a>
                  </li> 
                 
            </ul>
            <div class="copyright">© 2021 Youngspacegroup</div>
        </div>
</footer>