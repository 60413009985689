<!-- <header>
    <div class="container">
        <a href="index-2.html" class="logo">
            <img src="images/logo.png" alt="" />
            <h1>UBL Group</h1>
        </a>
        <div class="social__links">
          <ul>
            <li>
              <a href="https://www.facebook.com/UBL-Group-103885151050259/" target="_blank"><i class="fab fa-facebook-f"></i></a>
            </li>
            <li>
              <a href="https://twitter.com/group_ubl" target="_blank"><i class="fab fa-twitter"></i></a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/theublgroup" target="_blank"><i class="fab fa-linkedin-in"></i></a>
            </li>
            <li>
              <a href="https://www.instagram.com/Dubaiublgroup/" target="_blank"><i class="fab fa-instagram"></i></a>
            </li>
            <li>
              <a href="https://web.whatsapp.com/send?l=en&amp;phone=971526318888&amp;text=Hi,%20How%20are%20you?" target="_blank"><i class="fab fa-whatsapp" ></i></a>
            </li>
            <li>
              <a href="https://in.pinterest.com/theublgroup/" target="_blank"><i class="fab fa-pinterest-p"></i></a>
            </li>
          </ul>
        </div>
    </div>
  </header> -->

  <app-header></app-header>
  <div class="bg msg">
      <!-- <div class="intro bg1">
        <div class="container">
          <div class="col">
            <h2>Chairman's Message</h2>
          </div>
        </div>
      </div> -->
      <section>
          <div class="container">
              <div class="flexbox">
                  <div class="col__4">
                    <div class="img-container">
                      <img src="../../../assets/images/Chairman-youngspace-3.jpeg" alt="">
                      <div class="details">
                        <h2 class="text-center">Vinod Kuttan</h2>
                        <p class="text-center">Young Space Chairman</p>
                      </div>
                    </div>
                      <!-- <img src="../../../assets/images/Chairman-youngspace.jpeg" alt=""> -->
                  </div>
                  <div class="col__8">
                   <!--  <p class="decor_font is-700 is-italic is-large">"We are proud of our accomplishments and are confidently optimistic about the future."</p> -->
                      <p>Choose your job, not your company. Choose a final destination that goes beyond your job.
                        People peak at the age of 25. You are never too young to discover your destiny.Starting a job is a positive life changer.
                        Some young people only worry about the first step – getting into a company. I believe you should think about starting your job first, not about joining a company. Whatever work you do, only when you are doing it in an actual workplace, will you become a mature member of society.
                        Universities don’t teach this skill. Students might hone their memorization skills to do well on tests, but they don’t acquire the communication skills or judgment required for work.
                        Students who think they are mature upon graduating from university are sorely mistaken.
                        I always say that people peak at the age of 25. It’s often said that our fundamental thinking and capabilities are determined by 25, regardless of education and art.
                        While a growth curve varies by individual, you are never too young to discover your destiny".
                        Since humans are limited in their abilities, it makes no sense to think about what’s impossible. Realizing at an early stage what has been given to you is important.
                        We are social animals so we understand this just from our relationships with others. You can only discover it while connecting with others.
                        <br><br>
                        Regards,
                        Vinod Kuttan</p>
                     <!--  <h2>We believe in…</h2>
                      <ul class="list">
                          <li>Empowering young minds and training them for a brighter future for humanity.</li>
                          <li>Energizing and supporting the business community to ensure a prosperous tomorrow.</li>
                          <li>Remembering the society wherein we dwell and should always pay back to the community.</li>
                      </ul> -->
                  </div>
              </div>
          </div>
      </section>
  </div>
<app-footer></app-footer>
