<app-header></app-header>
  <div class="bg msg">
      <!-- <div class="intro bg1">
        <div class="container">
          <div class="col">
            <h2>Chairman's Message</h2>
          </div>
        </div>
      </div> -->
      <section>
          <div class="container">
            <div class="row">
                <div class="col-12">
                 <h2 class="text-center mb-3">
                     Our Services
                 </h2>
                </div></div>
              <div >

                <div class="row">
                    <div class="col__4">
                       <div class="card my-2">
                           <img src="../../../assets/images/services/party.jpg" class="card-img-top" alt="...">
                               <div class="card-body">
                       <h5 class="card-title">Parties & Entertainment</h5>
                      </div>
                       </div>
                    </div>
                    <div class="col__4">
                       <div class="card my-2">
                           <img src="../../../assets/images/services/portfolio.jpg" class="card-img-top" alt="...">
                               <div class="card-body">
                       <h5 class="card-title">Portfolio</h5>
                      </div>
                       </div>
                   </div>
                   <div class="col__4">
                       <div class="card my-2">
                           <img src="../../../assets/images/services/seminar.jpg" class="card-img-top" alt="...">
                               <div class="card-body">
                       <h5 class="card-title">Conference & Seminar Organizing</h5>
                      </div>
                       </div>
                   </div>
                </div>

                <div class="row">
                    <div class="col__4">
                       <div class="card my-2">
                           <img src="../../../assets/images/services/function-shoot.jpg" class="card-img-top" alt="...">
                               <div class="card-body">
                       <h5 class="card-title">Private Function Shoot</h5>
                      </div>
                       </div>
                    </div>
                    <div class="col__4">
                       <div class="card my-2">
                           <img src="../../../assets/images/services/film-production.jpg" class="card-img-top" alt="...">
                               <div class="card-body">
                       <h5 class="card-title">Film, Tv & Radio Production</h5>
                      </div>
                       </div>
                   </div>
                   <div class="col__4">
                       <div class="card my-2">
                           <img src="../../../assets/images/services/exhibition.jpg" class="card-img-top" alt="...">
                               <div class="card-body">
                       <h5 class="card-title">Exhibition Organizing</h5>
                      </div>
                       </div>
                   </div>
                </div> 

                 <div class="row">
                     <div class="col__4">
                        <div class="card my-2">
                            <img src="../../../assets/images/services/events.jpg" class="card-img-top" alt="...">
                                <div class="card-body">
                        <h5 class="card-title">Event Organizing & Managing</h5>
                       </div>
                        </div>
                     </div>
                     <div class="col__4">
                        <div class="card my-2">
                            <img src="../../../assets/images/services/performing-arts.jpg" class="card-img-top" alt="...">
                                <div class="card-body">
                        <h5 class="card-title">Performing Arts</h5>
                       </div>
                        </div>
                    </div>
                    <div class="col__4">
                        <div class="card my-2">
                            <img src="../../../assets/images/services/dj.jpeg" class="card-img-top" alt="...">
                                <div class="card-body">
                        <h5 class="card-title">DJ NIGHT CLUB PARTIES</h5>
                       </div>
                        </div>
                    </div>
                 </div> 

                  <div class="row">
                    <div class="col__4">
                       <div class="card my-2">
                           <img src="../../../assets/images/services/fashion-show.jpg" class="card-img-top" alt="...">
                               <div class="card-body">
                       <h5 class="card-title">Fashion Show</h5>
                      </div>
                       </div>
                    </div>
                    <div class="col__4">
                       <div class="card my-2">
                           <img src="../../../assets/images/services//modelling-training.jpg" class="card-img-top" alt="...">
                               <div class="card-body">
                       <h5 class="card-title">Fashion Model Training </h5>
                      </div>
                       </div>
                   </div>
                   <!-- <div class="col__4">
                       <div class="card my-2">
                           <img src="../../../assets/images/services/wedding-shoot.jpg" class="card-img-top" alt="...">
                               <div class="card-body">
                       <h5 class="card-title">Party Event</h5>
                      </div>
                       </div>
                   </div> -->
                </div>  

              </div>
          </div>
      </section>
  </div>
<app-footer></app-footer>