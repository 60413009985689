<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">Admin Panel</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link " aria-current="page" href="#">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="router-link-active" [routerLink]="['/show']"  >Show</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"  [routerLink]="['/admin']">Add List</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
<div class="container">
    <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">List</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of list; let i = index">
            <th scope="row">{{i+1}}</th>
            <td>{{item.name}}</td>
            <td>
                <button type="button" class="btn bg-primary" [routerLink]="['/admin', item.id]" routerLinkActive="router-link-active" 
                 >Edit</button>
                <button type="button" class="btn bg-danger" 
                (click)="delete(item.id)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
</div>
