<header>
    <div class="container">
        <a [routerLink]="['']"  class="logo">
            <img src="../../../assets/images/Young_Space_logo.png"  alt="" />
            <!-- <h1 class="text-capitalize">Young Space</h1> -->
            <div class="d-block my-2 text-center" style="width: 232px;">
              <h1 id="head-title" class="text-capitalize p-0">Young Space<br>
              </h1>
              <span class="text-center text-uppercase p-0 label" style="color:white; font-size: 12px;">"Land O’ the Leal"</span>
            </div>
           
            
        </a>
        <div class="social__links">
          <ul>
            <li>
              <a href="https://www.facebook.com/Young-Space-Fashion-Events-112037591156041" target="_blank"><i class="fab fa-facebook-f"></i></a>
            </li>
            <li>
              <a href="https://twitter.com/youngspacegroup/" target="_blank"><i class="fab fa-twitter"></i></a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/young-space-group/" target="_blank"><i class="fab fa-linkedin-in"></i></a>
            </li>
            <li>
              <a href="https://www.instagram.com/youngspacegroup/" target="_blank"><i class="fab fa-instagram"></i></a>
            </li>
            <li>
              <a href="https://web.whatsapp.com/send?l=en&amp;phone=+971 5090 65492&amp;text=Hi,%20How%20are%20you?" target="_blank"><i class="fab fa-whatsapp" ></i></a>
            </li>
            <li>
              <a href="https://www.pinterest.com/youngspacegroup/" target="_blank"><i class="fab fa-pinterest-p"></i></a>
            </li>
          </ul>
        </div>
    </div>
  </header>