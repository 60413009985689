  <app-header></app-header>
  <div class="bg msg pb-0">
      <!-- <div class="intro bg1">
        <div class="container">
          <div class="col">
            <h2>Chairman's Message</h2>
          </div>
        </div>
      </div> -->
      <section class="mb-0 pb-0">
            
                  <!-- <div class="col__4">
                      <img src="images/ubl-chairman.jpg" alt="">
                  </div> -->

                  <div class="row">
                  <div class="col-12">
                   <h2 class="text-center">
                       Get In Touch
                   </h2>
                  </div></div>

<div class="row">
    <div class="col__4">
        
        <div class="d-block mx-2 my-5 contactwrapper">
            
                <div class="my-3 d-md-flex d-block contactdiv mx-auto">
                   <i class="fa fa-envelope pt-2"><!-- <span class="px-2 contacticon">Email</span> --> </i> <br>
                    <span class="px-3 contacticon">events@youngspacegroup.com </span> 
                </div>
                <div class=" my-5 d-md-flex d-block contactdiv mx-auto">
                    <i class="fa fa-phone pt-2"><!-- <span class="px-3 contacticon">Phone</span> --></i> <br>
                    <span class="px-3 contacticon">+971 567 655 492</span>
                </div>
                <div class=" my-5 d-md-flex d-block contactdiv mx-auto">
                    <i class="fab fa-whatsapp pt-2"><!-- <span class="px-2 contacticon">WhatsApp</span>  --></i><br>
                    <span class="px-3 contacticon">+971 5090 65492</span>
                </div>
                
                <div class=" my-5 d-md-flex d-block contactdiv mx-auto">
                    <i class="fa fa-map-marker pt-2"><!-- <span class="px-2 contacticon">Address</span> --></i><br>
                    <span class="px-3  contacticon ">Hamsah A Building
                        UWBC – Corridor – 16, Office – 281
                        Mezzanine Floor – Khalid Bin Waled Rd,
                        Dubai – UNITED ARAB EMIRATES
                        </span>
                </div>
           </div>
           
    </div>
    <div class="col__8">
        <form #formdata="ngForm" (ngSubmit)="onSubmit(formdata)" id="formd">
            <div class="my-5 mx-3">
                <h2 class="text-center pt-0 mt-0 mb-3">
                    Get Connect With Us
                </h2>
                <label >Name</label><br>
                <input type="text" class="contactinput my-2 " ngModel name="name" required><br>
                <label class="mt-3">Email</label><br>
                <input type="text" class="contactinput my-2 "  ngModel name="email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" ><br>
                <label class="mt-3">Phone Number</label><br>
                <input type="number" class="contactinput my-2 " ngModel name="number" required><br>
                <label class="mt-3">Message</label><br>
                <textarea type="text" class="contactinput my-2 " ngModel name="message" required></textarea><br>
                <button type="submit" class="btn bg-light" (click)="showSuccess()" [disabled]="!formdata.valid" style="color: black;">Submit</button>
            </div>
        </form>
    </div>
</div>
<div class="row mb-0 ">
    <div class="col-12">
        <div class="mb-0 mt-3">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7217.072887832072!2d55.3025229!3d25.2525337!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43ada70b9ea1%3A0xc57e752987970b75!2sYoung%20space%20Group!5e0!3m2!1sen!2sin!4v1690898310424!5m2!1sen!2sin" 
            width="100%"  height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
           </div>
    </div>
</div>
      </section>
  </div>
<app-footer></app-footer>